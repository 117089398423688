import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

 @BlockUI() blockUI: NgBlockUI;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  //onSubmit(): void {
  //  const queryParams = {
  //    productId: '8D7C6657-1E89-EE11-8179-002248993DB5',
  //    productType: 'ca7784ee-13e9-ea11-a817-0022489d8bbc',
  //    agentId: '7B11A984-87D8-EA11-A813-000D3A666BFA',
  //    contactId: '00000000-0000-0000-0000-000000000000',
  //    insuranceCompanyId: 'ae51a16f-d2db-ea11-a813-0022489d8bbc',
  //    partnerId: '265f78cd-25f3-ea11-a815-000d3ab1d47c',
  //    currencyId: 'd009d7f5-bdd3-ea11-a813-000d3ab85fa8'
  //  };

  //  this.router.navigate(['/otc'], { queryParams });
  //}

  //onSubmit(): void {
  //  console.log("Navigating to /otc with parameters", queryParams);
  //  this.router.navigate(['/otc'], { queryParams }).then(success => {
  //    console.log("Navigation success:", success);
  //  }).catch(err => {
  //    console.error("Navigation error:", err);
  //  });
  //}

  onSubmit(): void {

   this.blockUI.start();
    setTimeout(() => {
     this.blockUI.stop();
      
    }, 2000);

   const queryParams = {
      productId: '8D7C6657-1E89-EE11-8179-002248993DB5',
      productType: 'ca7784ee-13e9-ea11-a817-0022489d8bbc',
      agentId: '7B11A984-87D8-EA11-A813-000D3A666BFA',
      contactId: '00000000-0000-0000-0000-000000000000',
      insuranceCompanyId: 'ae51a16f-d2db-ea11-a813-0022489d8bbc',
      partnerId: '265f78cd-25f3-ea11-a815-000d3ab1d47c',
      currencyId: 'd009d7f5-bdd3-ea11-a813-000d3ab85fa8'
    };

    console.log("Attempting to navigate with queryParams", queryParams);

    this.router.navigate(['/otc'], { queryParams }).then(success => {
      if (success) {
        console.log("Navigation was successful!");
      } else {
        console.log("Navigation failed!");
      }
    }).catch(err => {
      console.error("Navigation error:", err);
    });

    //this.router.navigateByUrl('/otc?productId=8D7C6657-1E89-EE11-8179-002248993DB5&productType=ca7784ee-13e9-ea11-a817-0022489d8bbc&agentId=7B11A984-87D8-EA11-A813-000D3A666BFA&contactId=00000000-0000-0000-0000-000000000000&insuranceCompanyId=ae51a16f-d2db-ea11-a813-0022489d8bbc&partnerId=265f78cd-25f3-ea11-a815-000d3ab1d47c&currencyId=d009d7f5-bdd3-ea11-a813-000d3ab85fa8');


  }



}
